<template>
  <el-row :gutter="12">
    <el-col :span="12" :xs="24" :md="12" :sm="12">
      <!--            <el-input :title="infoData"  v-model="infoData"></el-input>-->
      <el-card class="box-card">
        <div class="clearfix" slot="header">
          <el-link class="title">基本信息（内）</el-link>
        </div>
        <el-form class="newForm" label-suffix="：">
          <el-form-item label="项目" v-show="infoData.name!=''">
            {{ infoData.name || '' }}
          </el-form-item>
          <el-form-item label="建单人" v-show="infoData?.create_user?.nickname!==''">
            {{ infoData?.create_user?.nickname || '' }}
          </el-form-item>
          <el-form-item label="意向达人" v-show="infoData.intentions!=''">
            <span :key="i.id" v-for="i in infoData.intentions">{{ i.nickname }}</span>
          </el-form-item>
          <el-form-item label="品牌" v-show="infoData.brand!=''">
            {{ infoData.brand || '' }} <span
              style="color: #E6A23C">（是否寄回：{{ infoData.is_send_back == 1 ? '是' : '否' }}）</span>
          </el-form-item>
          <el-form-item label="产品" v-show="infoData.product!=''">
            {{ infoData.product || '' }}
          </el-form-item>
          <el-form-item label="所属集团" v-show="infoData?.group?.display_name!=''">
            {{ infoData?.group?.display_name || '' }}
          </el-form-item>
          <el-form-item
              label="产品资料"
              v-show="intro!==''||infoData.attachments||false">
            <!--            {{ infoData.intro || '' }}-->
            <span v-html="this.intro"></span>
            <div v-if="infoData.attachments?.length>0||false">
              <span v-for="item in infoData.attachments" :key="item.id">
                   <i class="el-icon-download" style="color: #ff3176"></i>
              &nbsp;
              <a :href="item.full_path" target="_blank"
              >{{ item.original || '附件下载' }}</a>
              </span>
            </div>
          </el-form-item>
          <el-form-item label="平台"><span :key="i.value" v-for="i in infoPlatforms"> {{ i.name }} </span>
            <span style="color: #E6A23C;padding-left: 20px">{{ infoData.coop ? infoData.coop.display_name : '' }}</span>
          </el-form-item>
          <el-form-item
              label="挂车佣金"
              v-show="infoData.is_cart===2">
                        <span v-show="infoData.is_cart===2">
                            {{ infoData.cart_rate }}%
                     </span>

          </el-form-item>
          <el-form-item
              label="挂车机制"
              v-show="infoData.is_cart===2">
                        <span v-show="infoData.is_cart===2">
                            {{ infoData.cart_mechanism }}
                     </span>

          </el-form-item>
          <el-form-item
              label="授权平台"
              v-show="infoData.is_auth_price===2&&infoData.auth_platform.length?.length>0">
                        <span v-show="infoData.is_auth_price===2"
                              v-for="(item,index) in infoData?.auth_platform_alias" :key="item+index">
                            {{ item }},
                     </span>
          </el-form-item>
          <el-form-item
              label="期望档期">
            {{ infoData.expect_start }}至{{ infoData.except_end }}
          </el-form-item>
          <el-form-item
              label="直播明细"
              v-show="live_detail!==''">
            {{ this.live_detail }}
          </el-form-item>
          <el-form-item
              label="其他要求"
              v-show="other_desc!==''">
            {{ this.other_desc }}
          </el-form-item>
          <el-form-item style="color: red" label="说明">报名时不备注默认都接受</el-form-item>
        </el-form>

        <div class="bird_box" type="info" v-clipboard:copy="copyData"
             v-clipboard:error="onError" v-clipboard:success="onCopyInfo">
          <el-button type="primary" size="big" style="float: right;margin-bottom: 10px">复制</el-button>
        </div>
      </el-card>
    </el-col>
    <el-col :span="12" :xs="24" :md="12" :sm="12">
      <el-card class="box-card">
        <div class="clearfix" slot="header">
          <el-link class="title">基本信息（外）
          </el-link>
        </div>
        <el-form class="newForm" label-suffix="：" ref="form">
          <el-form-item
              label="贵司名称">
            {{ infoData.brand || '' }}
          </el-form-item>

          <el-form-item
              label="意向达人"
              v-show="infoData.intentions!=''">
                       <span :key="i.id" v-for="i in infoData.intentions">
                           {{ i.nickname }}
                       </span>
          </el-form-item>
          <el-form-item
              label="平台">
                            <span :key="i.value" v-for="i in infoPlatforms">
                                {{ i.name }},
                            </span>
            <span style="color: #E6A23C;padding-left: 20px">{{ infoData.coop ? infoData.coop.display_name : '' }}</span>
          </el-form-item>
          <el-form-item
              label="产品资料"
              v-show="this.intro!=''">
            <span v-html="this.intro"></span>
          </el-form-item>
          <el-form-item
              label="期望档期"
              v-show="infoData.expect_start!=''">
            {{ infoData.expect_start }}至{{ infoData.except_end }}
          </el-form-item>
          <el-form-item
              label="直播明细"
              v-show="this.live_detail!=''">
            {{ this.live_detail }}
          </el-form-item>
          <el-form-item
              label="其他要求"
              v-show="this.other_desc!=''">
            {{ this.other_desc }}
          </el-form-item>
          <el-form-item style="color: red" label="说明">报名时不备注默认都接受</el-form-item>

          <div class="bird_box" v-clipboard:copy="copyInfo"
               v-clipboard:error="onError" v-clipboard:success="onCopyInfo">
            <!--            <BirdButton :bird="false" :text="'复制'"></BirdButton>-->
            <el-button type="primary" size="big" style="float: right;margin-bottom: 10px">复制</el-button>
          </div>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import BirdButton from '@/components/Style/birdButton.vue'

export default {
  name: 'infoCard',
  data() {
    return {
      infoData: {},
      //已有平台
      infoPlatforms: [],
      projectId: '',
      copyInfo: '请重试',
      copyData: '请重试',
      intro: '',
      other_desc: '',
      live_detail: ''
    }
  },
  components: {
    BirdButton
  },
  methods: {
    onCopyInfo() {
      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    onError() {
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
    async getInfo() {
      let { info } = await this.$api.infoInquiryProject({ id: this.projectId })
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      let platforms = list
      platforms.forEach((i) => {
        if (i.name === 'package') {
          i.children.forEach((v) => {
            platforms.push(v)
          })
        }
      })
      let regexp = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|\\&|-)+)/g
      this.intro = info['intro'].replace(regexp, function($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      })
      this.other_desc = info['other_desc'].replace(regexp, function($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      })
      this.live_detail = info['live_detail'].replace(regexp, function($url) {
        return '<a href=\'' + $url + '\' target=\'_blank\'>' + $url + '</a>'
      })
      //InquiryKolList
      this.$nextTick(() => {
        // this.infoData = info
        let mapNewArray = []
        for (let i in platforms) {
          info.platforms.forEach((item) => {
            if (item !== 'package' && item === platforms[i].name) {
              mapNewArray.push({ name: platforms[i].display_name, value: item })
            }
          })
        }
        this.infoPlatforms = mapNewArray
        this.infoData = info
        this.getCopyData(info)
        this.$emit('infoPlatform', this.infoPlatforms)
        this.$emit('update:infoStatus', { status: info.status, is_apply: info.is_apply, note: info.other_desc })
      })
    },
    getCopyData(info) {
      let nickname = []
      if (info.intentions) {
        nickname = info.intentions.map((i) => {
          return i.nickname
        })
      }
      let Platforms = []
      if (this.infoPlatforms) {
        Platforms = this.infoPlatforms.map((i) => {
          return i.name
        })
      }
      //内部copydata
      let data = `项目：${info.name}\n建单人：${info?.create_user?.nickname}\n意向达人：${String(nickname) || '无'}\n平台：${String(Platforms) || '暂无'}\n合作形式：${info.coop ? info.coop.display_name : '暂无'}\n品牌：${info.brand || '暂无'}\n产品：${info.product || '暂无'}\n产品资料：${info.intro || '暂无'}\n挂车佣金：${info.cart_rate + '%' || ''}\n挂车机制：${info.cart_mechanism || '暂无'}\n授权平台：${info?.auth_platform?.display_name ?? ''}\n期望档期：${info.expect_start}至${info.except_end}\n直播明细：${info.live_detail || '暂无'}\n其他要求：${info.other_desc || '暂无'}\n说明：报名时不备注默认都接受`
      //外部copydaTA
      let html = `贵司名称：${info.name}\n意向达人：${String(nickname) || '无'}\n平台：${String(Platforms) || '暂无'}\n合作形式：${info.coop ? info.coop.display_name : '暂无'}\n品牌：${info.brand || '暂无'}\n产品：${info.product || '暂无'} \n产品资料：${info.intro || '暂无'}\n期望档期：${info.expect_start}至${info.except_end}\n直播明细：${info.live_detail || '暂无'}\n其他要求：${info.other_desc || '暂无'}\n说明：报名时不备注默认都接受`
      this.copyData = data
      this.copyInfo = html
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
    this.getInfo()
  }
}
</script>

